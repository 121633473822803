* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
#root {
  min-height: 100%;
}
.app {
  width: 100%;
}
.__main {
  max-width: 100vw;
  border-radius: 10px;
  display: flex;
  margin-top:40px;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
}
/* .nav-header{
  background-color: aqua;
} */
.FixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
}

@media screen and (min-width: 768px) {
  #root {
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
  }
  .__main {
    margin: auto;
    margin-top: 60px;
    background-color: #fff;
    min-height: 80vh;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
  }
  .header {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .search_wrap {
    width: 50% ;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}

.slection-title {
  width: 100%;
  padding: 1vw;
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
}
.search_wrap {
  background-color: #e6e5ea;
  border-radius: 5px;
}
.search_wrap input {
  background-color: transparent;
  border: none;
  padding: 15px 15px;
  outline: none;
  width: 80%;
  padding-right: 0;
}
.search-btn {
  height: 46px;
  border: none;
  background-color: transparent;
  outline: none;
  width: 20%;
  cursor: pointer;
  font-size: 20px;
}
