.main__body {
  flex-grow: 1;
  background-color: #f3b2c8;
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
}
.LoadMore{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
