.main__content {
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px){
  .main__content {
    flex-grow: 1;
    padding: 0px 40px;
  }
}
.Card{
  margin: 10px;
  box-shadow: 0 2px 15px rgb(0 0 0 / 10%);
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
}

.desc{
  height: 60px;
}
.desc span{
  overflow: hidden;
}
.name{
  min-height: 50px;
}
.name span{
  font-weight: bolder;
}
.price span{
  font-weight: bolder;
  color: red;
}
#sendMsgBtn {
  background-color: #3b5bfe;
  color: #fff;
}
.seperator {
  width: 100%;
}

.controls-wrapper {
  width: 100%;
}

.carousel-wrapper {
  width: 100%;
}

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}
@media (max-width: 768px) {
  .corssal div:first-child button:last-child,
  .corssal div:first-child button:first-child {
    display: none;
  }
}
.rec .rec-pagination{
  display: none;
}
.labelclass {
	display: block;
	width: 288px;
	height: 429px;
	perspective: 1000px;
	transform-style: preserve-3d;
	cursor: pointer;
  margin: 25px 10px;
}

.flip-card {
	position: relative;
	width: 100%;
  height: 100%;
	transform-style: preserve-3d;
	transition: all 0.5s ease-in-out;
	z-index: 1;
}

.flip-card .front,
.flip-card .back {
	position: absolute;
	text-align: center;
	backface-visibility: hidden;
}

.flip-card .back {
	transform: rotateX(180deg);
	color: #000;
}

.labelclass:hover .flip-card {
	transform: rotateX(2deg);
}

.flippedCheck {
	display: none;
}

:checked + .flip-card {
	transform: rotateX(180deg);
}
.labelclass:hover :checked + .flip-card {
	transform: rotateX(175deg);
}