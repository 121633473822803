.footer {
  background-color: #191919;
  padding: 70px 30px ;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
.footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;
}
.footer .box h3 {
  color: white;
  font-size: 50px;
  margin: 0 0 20px;
}
.footer .box .social {
  display: flex;
}
@media (max-width: 767px) {
  .footer .box .social {
    justify-content: center;
  }
}
.footer .box .social li {
  margin-right: 10px;
}
.footer .box .social li span {
  background-color: #313131;
  color: #b9b9b9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.footer .box .social .facebook:hover {
  background-color: #1877f2;
}
.footer .box .social .whatsapp:hover {
  background-color: #ff0000;
}
.footer .box .text {
  line-height: 2;
  color: #b9b9b9;
}

.footer .box .line {
  display: flex;
  align-items: center;
  color: #b9b9b9;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .footer .box .line {
    flex-direction: column;
  }
}
.footer .box .line i {
  font-size: 25px;
  color: #2196f3;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .footer .box .line i {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.footer .box .line .info {
  line-height: 1.7;
  flex: 1;
}
.footer .box .line .info span {
  display: block;
}