/* header */
#intro {
  margin-top: 75px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/1.jpg");
  min-height: 85vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}
.hero-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-div h1 {
  font-size: 1.55rem;
  padding-bottom: 0.4rem;
}
.hero-div h1 span {
  color: #ffffff;
  font-weight: bolder;
  font-size: 2rem;
}
.hero-div p {
  font-size: 1.3rem;
  letter-spacing: 3px;
}
/* #### Media Queries #### */

@media screen and (max-width: 768px) {
  #intro {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../images/5.jpg");
    background-size: cover;
  }
}

@media screen and (min-width: 992px) {
  .hero-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-div h1 {
    font-size: 2.8rem;
  }
  .hero-div h1 span {
    font-size: 2.5rem;
    color: #f9004d;
    font-weight: bolder;
  }
}
@media screen and (min-width: 1200px) {
  .hero-div h1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.go_down {
  color: #2196f3;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
@media screen and (min-width: 1200px) {
  .go_down {
    bottom: 20px;
  }
}
.go_down:hover {
  color: #1787e0;
  cursor: pointer;
}
@keyframes bouncing {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40%,
  60% {
    transform: translateY(-15px);
  }
}
.go_down i {
  animation: bouncing 1.5s infinite;
  -webkit-animation: bouncing 1.5s infinite;
}
